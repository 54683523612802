<template>
<div class="point-config-card">
  <b-card no-body>
    <b-card-header class="p-1 d-flex">
      <div>
        <h3>{{$t(`config.points.${type}`)}}</h3>
      </div>
      <div style="letter-spacing: 1rem;">
        <b-link variant="outline-primary" size="sm" class="ml-auto" @click="loadPointConfig" v-b-tooltip :title="$t('operations.refresh')">
          <i class="fa fa-refresh"></i>
        </b-link>
        <b-link variant="outline-primary" size="sm" class="ml-auto" @click="addRow" v-b-tooltip :title="$t('operations.add')">
          <i class="fa fa-plus"></i>
        </b-link>
        <b-link size="sm" class="text-warning ml-auto" @click="save" v-b-tooltip :title="$t('operations.save')">
          <i class="fa-regular fa-floppy-disk"></i>
        </b-link>
      </div>
    </b-card-header>
    <b-table-simple class="m-0" hover small caption-top responsive>
      <b-thead head-variant="light">
        <b-tr>
          <b-th style="width: 50%">
            <span v-if="numeric !== true">{{ $t('words') }}</span>
            <span v-else>{{ $t('comparing.greaterThan') }}</span>
          </b-th>
          <b-th style="width: 30%">{{$t('columns.points')}}</b-th>
          <b-th style="width: 20%"></b-th>
        </b-tr>
      </b-thead>
    </b-table-simple>
    <perfect-scrollbar class="position-relative" style="height: 300px">
      <b-table-simple class="m-0" hover small caption-top responsive>
      <b-tbody>
        <b-tr v-bind:class="{'row-edited':item.edited, 'row-deleted': item.deleted}" v-for="(item,index) in pointConfig" :key="index">
          <!--key-->
          <b-td v-if="item.editing">
            <b-input :number="numeric === true" size="sm" v-model="item.key" @change="toCamelCase(item)"/>
          </b-td>
          <b-td v-else>{{item.key}}</b-td>
          <!--value-->
          <b-td v-if="item.editing">
            <b-input number size="sm" style="width: 100px" v-model="item.value" />
          </b-td>
          <b-td v-else>{{item.value}}</b-td>
          <!--action-->
          <b-td v-if="item.editing" style="letter-spacing: 1rem;">
            <b-link variant="outline-primary" size="sm" class="ml-auto" @click="saveRow(index)" v-b-tooltip.hover.bottom :title="$t('operations.save')">
              <i class="fa-solid fa-check"></i>
            </b-link>
            <b-link variant="outline-primary" size="sm" class="ml-auto" @click="cancelEditRow(index)" v-b-tooltip.hover.bottom :title="$t('operations.cancel')">
              <i class="fa-solid fa-xmark"></i>
            </b-link>
          </b-td>
          <b-td v-else>
            <b-link variant="outline-primary" size="sm" class="ml-auto" @click="editRow(index)" v-b-tooltip.hover.bottom :title="$t('operations.edit')">
              <i class="fa-regular fa-pen-to-square"></i>
            </b-link>
          </b-td>
        </b-tr>
      </b-tbody>
    </b-table-simple>
    </perfect-scrollbar>
  </b-card>

</div>
</template>

<script>
import { mapActions } from 'vuex'
import { camelize } from '@/model/Utils'
import ToastificationContent from '@core/components/toastification/ToastificationContent'

export default {
  key: 'pointCard',
  data:()=>({pointConfig:[]}),
  props: ['type','value', 'numeric'],
  watch:{
    value: {
      handler: function (val, oldVal) {
        this.loadPointConfig();
      },
      immediate: true
    }
  },
  computed:{
    edited(){
      return this.pointConfig.filter(item => item.edited).length > 0;
    }
  },
  methods: {
    ...mapActions('suitPointConfig',['saveSuitPointConfig']),
    loadPointConfig() {
      let $this = this;
      this.pointConfig = this.value.map(item => {
        return {
          originalKey: item.key,
          key: $this.numeric !== true ? item.key : item.keyNumeric,
          value: item.value,
          editing: false,
          edited: false,
          deleted: false
        }
      });
    },
    toCamelCase(item){
      item.key = item.key.toLowerCase();
    },
    addRow(){
      this.pointConfig.push({ key: '', value: '', editing: true, edited: false });
    },
    editRow(index){
      let $this = this;
      this.pointConfig.forEach((item, i) => {
        if(item.editing) {
          $this.cancelEditRow(i)
        }
      });
      this.pointConfig[index].editing = true;
    },
    cancelEditRow(index){
      let key = this.pointConfig[index].originalKey;
      let originalValue = key != null ? this.value[key] : null;
      if(originalValue == null) {
        this.pointConfig.deleted = true;
        this.pointConfig.splice(index, 1);
      }else {
        this.pointConfig[index].key = key;
        this.pointConfig[index].value = originalValue;
        this.pointConfig[index].editing = false;
      }
    },
    saveRow(index){
      this.pointConfig[index].editing = false;
      this.pointConfig[index].edited = true;
    },
    async save(){
      let $this = this;
      let newPointValues = this.pointConfig.map(item => ({
        [$this.numeric !== true ? 'key' : 'keyNumeric']: item.key,
        value: item.value
      }))
      this.saveSuitPointConfig({configType: this.type, values: newPointValues }).then(
          ()=>{
            $this.$emit('input', newPointValues);
            $this.loadPointConfig();
            $this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Form Submitted ',
                icon: 'EditIcon',
                variant: 'success',
              },
            })
          },
          err=>{
            $this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Error',
                text: err.error,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          }
      )


    }
  }


}
</script>

<style lang="scss">
.point-config-card{
  .row-edited{
    background-color: #ffe70026;
  }
  .row-deleted{
    background-color: rgba(255, 0, 8, 0.15);
  }
}
.ps-container > .ps-scrollbar-x-rail,
.ps-container > .ps-scrollbar-y-rail {   opacity: 0.6; }
</style>
