<template>
<div>
  <b-row>
    <b-col cols="6" v-for="point in pointsConfig" :key="point.configType">
      <point-card :numeric="point.numeric===true" :type="point.configType" v-model="point.values"></point-card>
    </b-col>
  </b-row>
</div>
</template>

<script>

import { mapActions, mapGetters } from 'vuex'
import PointCard from '@/components/partial/config/pointCard'

export default {
  name: 'SuitPointView',
  components: { PointCard },
  data:()=>({pointsConfig:[]}),
  created() {
    this.refresh();
  },
  computed:{
    ...mapGetters('suitPointConfig', ['pointTypes'])
  },
  methods: {
    ...mapActions('suitPointConfig', ['getSuitPointConfig']),
    async refresh() {
      let data = await this.getSuitPointConfig();
      let dataMap = data.map(item=> item.configType);
      this.pointTypes.forEach(type => {
        if(!dataMap.includes(type)) {
          data.push({configType: type, values: {}});
        }
      });
      console.log(data);
      this.pointsConfig = data;
    }
  }
}
</script>

<style scoped>

</style>
